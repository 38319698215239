




















import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { GET_USER_DETAILS, USER_STATE } from "@/store/modules/auth/constants";
import { GET_ROLE_BY_NAME } from "@/store/modules/common/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import router from "@/router";

export default Vue.extend({
  name: "OnboardingSuccess",
  data() {
    return {
      success_animation: require("@/assets/illustrations/onboarding-success.json")
    };
  },
  async created() {
    this.success_animation = JSON.stringify(this.success_animation);
  },
  computed: {
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    }),
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapMutations("auth", {
      update_user_state: USER_STATE
    }),
    async navigate_to_dashboard() {
      const recruiter_role = this.get_role_by_name("recruiter");
      const candidate_role = this.get_role_by_name("individual");
      if (!this.get_user_details.is_account_confirmed) {
        await router.push("/approval-pending");
      } else if (this.get_user_details.role_id === recruiter_role.id) {
        this.update_user_state(UserState.RECRUITER);
        await router.push({
          name: "recruiter-dashboard"
        });
      } else if (this.get_user_details.role_id === candidate_role.id) {
        this.update_user_state(UserState.CANDIDATE);
        await router.push({
          name: "candidate-dashboard"
        });
      }
    }
  }
});
